import moment from "moment";
import React, { useRef } from "react";
import Swal from "sweetalert2";
import { Transaction, UserRegistrant } from "../../interfaces/interfaces";
import { getApiInstance, getBadgeType } from "../../utils/helper";
import { BaseFormGroup } from "../widgets/Form";
import DialogPickupLocation from "./DialogPickupLocation";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
    registrant: UserRegistrant;
    setRegistrant: React.Dispatch<React.SetStateAction<UserRegistrant>>;
    handleImageUpload: any;
    showFullDetail: boolean;
};

export default function RegistrationDetail(props: Props) {
    const intl = useIntl();
    const dialogRef = useRef<HTMLDialogElement | null>();
    const registrant = props.registrant;

    const handleReregister = () => {
        Swal.fire({
            icon: "warning",
            title: "Re-register?",
            text: intl.formatMessage({
                id: "register.reset",
                defaultMessage:
                    "Apakah Anda yakin untuk melakukan registrasi ulang? Data registrasi Anda sebelumnya akan di reset kembali.",
            }),
            showCancelButton: true,
            confirmButtonColor: "#000",
            confirmButtonText: intl.formatMessage({
                id: "yes",
                defaultMessage: "Ya",
            }),
            cancelButtonText: intl.formatMessage({
                id: "no",
                defaultMessage: "Tidak",
            }),
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: () => {
                const instance = getApiInstance();
                return instance.post(
                    `/me/registration/cancel?bill_no=${registrant.registration_status?.transaction.bill_no}`
                );
            },
        })
            .then((confirm) => {
                if (confirm.isConfirmed) {
                    document.location = "/";
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const renderSelectPaymentMethodButton = (paymentChannelId?: number) => {
        if (
            registrant.registration_status?.transaction.status === "EXPIRED" ||
            registrant.registration_status?.transaction.status === "CANCELED"
        ) {
            return (
                <div>
                    <b>
                        <FormattedMessage
                            id="payment.notSelected"
                            defaultMessage="Anda belum memilih metode pembayaran"
                        />
                    </b>
                </div>
            );
        } else if (
            registrant.registration_status?.transaction.status === "WAITING"
        ) {
            if (paymentChannelId) {
                return (
                    <div className="form-control-static mb-5">
                        <b>
                            <FormattedMessage
                                id="payment.changePayment"
                                defaultMessage="Silahkan klik tombol di bawah jika Anda ingin
                            memilih metode pembayaran lain."
                            />
                        </b>
                        <a
                            href={
                                registrant.registration_status?.transaction
                                    .payment_url
                            }
                            className="btn btn-submit mt-1 w-100"
                        >
                            <FormattedMessage
                                id="payment.btnChangePayment"
                                defaultMessage="Ganti metode pembayaran"
                            />
                        </a>
                    </div>
                );
            } else {
                return (
                    <div className="form-control-static mb-2">
                        <b>
                            <FormattedMessage
                                id="payment.messageSelectPayment"
                                defaultMessage="Silahkan pilih metode pembayaran untuk melanjutkan
                                proses registrasi."
                            />
                        </b>
                        <div>
                            <a
                                href={
                                    registrant.registration_status?.transaction
                                        .payment_url
                                }
                                className="btn btn-submit mt-1"
                            >
                                <FormattedMessage
                                    id="payment.btnSelectPayment"
                                    defaultMessage="
                                Pilih metode pembayaran"
                                />
                            </a>
                        </div>
                    </div>
                );
            }
        }
    };

    const renderEventLocation = (transaction: Transaction) => {
        if (registrant.service_type === "online") {
            return <>Online</>;
        } else if (registrant.service_type === "on-site") {
            const items = transaction.items;
            if (!items || items.length === 0) {
                return <>On-site</>;
            }
            const churchName = items[0].church_name;
            return <>{churchName ?? "On-site"}</>;
        }
    };

    const renderRegistrationStatus = (transaction: Transaction) => {
        return (
            <div>
                {(transaction.status === "EXPIRED" ||
                    transaction.status === "CANCELED") && (
                    <div>
                        <div className="alert alert-danger">
                            {transaction.status === "CANCELED" &&
                                intl.formatMessage({
                                    id: "registration.canceled",
                                    defaultMessage:
                                        "Pendaftaran Anda telah dibatalkan. Segera hubungi hotline kami jika Anda tidak merasa melakukan pembatalan.",
                                })}
                            {transaction.status === "EXPIRED" &&
                                intl.formatMessage({
                                    id: "registration.expired",
                                    defaultMessage:
                                        "Pendaftaran Anda telah melewati batas waktu. Jika Anda telah melakukan pembayaran tetapi belum di verifikasi, silahkan hubungi hotline dengan menyertakan bukti pembayarandan bill number Anda.",
                                })}
                        </div>
                        <button
                            className="btn btn-submit w-100 mb-4"
                            onClick={handleReregister}
                        >
                            Re-register
                        </button>
                    </div>
                )}
                <div className="card">
                    <div className="card-body">
                        <BaseFormGroup
                            hidden={!props.showFullDetail}
                            label={intl.formatMessage({
                                id: "registrationDateLabel",
                                defaultMessage: "Tanggal Registrasi",
                            })}
                        >
                            <div className="form-control-static">
                                {moment(transaction.bill_at).format(
                                    "D MMM YYYY HH:mm"
                                )}
                            </div>
                        </BaseFormGroup>
                        <BaseFormGroup
                            label="Total Bayar"
                            hidden={!props.showFullDetail}
                        >
                            {(transaction.payment_channel_id > 0 ||
                                transaction.status === "PAID") && (
                                <div className="form-control-static">
                                    <b style={{ fontSize: "1.6em" }}>
                                        {new Intl.NumberFormat("id").format(
                                            transaction.bill_total
                                        )}
                                    </b>
                                </div>
                            )}
                            {transaction.payment_channel_id === 0 &&
                                renderSelectPaymentMethodButton()}
                        </BaseFormGroup>
                        <BaseFormGroup
                            label={intl.formatMessage({
                                id: "location.header",
                                defaultMessage: "Lokasi Event",
                            })}
                            hidden={!props.showFullDetail}
                        >
                            <div className="form-control-static">
                                {renderEventLocation(transaction)}
                            </div>
                        </BaseFormGroup>
                        <BaseFormGroup
                            hidden={!props.showFullDetail}
                            label={intl.formatMessage({
                                id: "registration.pickupLocation",
                                defaultMessage: "Lokasi Pengambilan Toolkit",
                            })}
                        >
                            <div className="form-control-static">
                                <div>
                                    <div>
                                        <b>{registrant.rereg_location}</b>
                                    </div>
                                    {registrant.rereg_location_address && (
                                        <div>
                                            {registrant.rereg_location_address}
                                        </div>
                                    )}
                                    <button
                                        onClick={() =>
                                            dialogRef.current?.showModal()
                                        }
                                        type="button"
                                        className="btn btn-submit"
                                    >
                                        <FormattedMessage
                                            id="registration.changePickupLocation"
                                            defaultMessage="Ubah lokasi pengambilan toolkit"
                                        />
                                    </button>
                                </div>
                            </div>
                        </BaseFormGroup>
                        <BaseFormGroup label="Bill No.">
                            <div className="form-control-static">
                                {transaction.bill_no}
                            </div>
                        </BaseFormGroup>
                        <BaseFormGroup label="Status">
                            <div className="form-control-static">
                                <span
                                    className={
                                        "badge " +
                                        getBadgeType(transaction.status)
                                    }
                                >
                                    {transaction.status}
                                </span>
                            </div>
                        </BaseFormGroup>
                        {transaction.status === "WAITING" && (
                            <div>
                                <BaseFormGroup
                                    label={intl.formatMessage({
                                        id: "registration.paymentLimit",
                                        defaultMessage:
                                            "Batas waktu pembayaran",
                                    })}
                                >
                                    <div className="form-control-static">
                                        <b>
                                            {moment(
                                                transaction.expired_at
                                            ).format("D MMM YYYY HH:mm")}
                                        </b>
                                    </div>
                                </BaseFormGroup>
                            </div>
                        )}
                    </div>
                </div>
                {transaction.payment_channel_id > 0 &&
                    renderSelectPaymentMethodButton(
                        transaction.payment_channel_id
                    )}
            </div>
        );
    };

    return (
        <>
            {registrant.registration_status &&
                registrant.registration_status.transaction &&
                registrant.registration_status.transaction.items &&
                registrant.registration_status.transaction.items[0]
                    .church_id && (
                    <DialogPickupLocation
                        dialogRef={(ref) => {
                            dialogRef.current = ref;
                        }}
                        churchId={
                            registrant.registration_status?.transaction.items[0]
                                .church_id
                        }
                        locationId={registrant.rereg_location_id}
                        callback={(
                            locationId,
                            locationName,
                            locationAddress
                        ) => {
                            registrant.rereg_location_id = locationId;
                            registrant.rereg_location = locationName;
                            registrant.rereg_location_address = locationAddress;
                            props.setRegistrant({ ...registrant });
                        }}
                    />
                )}
            {registrant.registration_status?.registered &&
                renderRegistrationStatus(
                    registrant.registration_status.transaction
                )}
        </>
    );
}
