import { useEffect, useState } from "react";
import { EventItem, ItemDiscount } from "../interfaces/interfaces";
import { getApiInstance } from "./helper";
import * as Sentry from "@sentry/react";

type Props = {
    item_discounts?: ItemDiscount[];
    show_all: boolean;
};

export default function useEventItems(props: Props) {
    const [items, setItems] = useState<EventItem[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const getData = async (signal: AbortSignal) => {
            const instance = getApiInstance();
            try {
                const rawResponse = await instance.get("/items", {
                    params: {
                        show_all: props.show_all ? 1 : 0,
                    },
                    signal: signal,
                });
                const tempItems = rawResponse.data.result;
                // jika ada potongan khusus
                if (props.item_discounts && props.item_discounts.length > 0) {
                    for (let itemDiscount of props.item_discounts) {
                        if (itemDiscount.value > 0) {
                            const item: EventItem = tempItems.find(
                                (o: EventItem) => o.id === itemDiscount.item_id
                            );
                            if (item) {
                                item.price -= itemDiscount.value;
                                // tambahan pengecekan bila ternyata value discount lebih besar dari 0
                                if (item.price < 0) {
                                    item.price = 0;
                                }
                            }
                        }
                    }
                }
                setItems(tempItems);
            } catch (error: any) {
                Sentry.captureException(error);
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        };
        /**
         * Abort signal agar request api sebelumnya di cancel bila memerlukan reload dan request belum selesai
         */
        const controller = new AbortController();
        getData(controller.signal);
        return () => {
            controller.abort();
        };
    }, [props.show_all]);

    return { items, isLoading, isError };
}
