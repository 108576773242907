import React, { useMemo, useState } from "react";
import { UserRegistrant, Workshop } from "../../interfaces/interfaces";
import useWorkshops from "../../utils/useWorkshops";
import RadioGroup from "../widgets/RadioGroup";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { getApiInstance } from "../../utils/helper";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import FeedbackWidget from "../widgets/FeedbackWidget";
import useFeedback from "../../utils/useFeedback";

type Props = {
    registrant: UserRegistrant;
};

const queryCacheKey = "workshops";
function WorkshopsCard({ registrant }: Props) {
    const intl = useIntl();
    const churchId = useMemo(() => {
        const items = registrant.registration_status?.transaction.items;
        if (items && items.length > 0) {
            // ambil salah 1 saja
            return items[0].church_id ?? "";
        } else {
            return "";
        }
    }, [registrant.registration_status?.transaction.items]);
    const { query } = useWorkshops(queryCacheKey, churchId);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [selectedWorkshop, setSelectedWorkshop] = useState(
        registrant.workshop_id ?? ""
    );
    // const { query: queryFeedback } = useFeedback(
    //     query.isFetched && query.data?.length === 0
    // );

    const renderWorkshop = (workshops: Workshop[]) => {
        return (
            <RadioGroup
                options={workshops.map((item) => {
                    return {
                        label: item.name,
                        description: (
                            <div>Quota tersisa: {item.left_capacity}</div>
                        ),
                        value: item.id,
                        isChecked: selectedWorkshop === item.id,
                        disabled: item.left_capacity <= 0,
                        onChange: () => handleWorkshopChange(item),
                    };
                })}
            />
        );
    };

    const handleWorkshopChange = (item: Workshop) => {
        // hanya boleh pilih jika quota masih tersedia
        if (item.left_capacity <= 0) return;
        Swal.fire({
            title: intl.formatMessage(
                {
                    id: "workshopConfirmation",
                    defaultMessage: "Pilih workshop {name}?",
                },
                {
                    name: item.name,
                }
            ),
            showCancelButton: true,
            confirmButtonColor: "#000",
            confirmButtonText: intl.formatMessage({
                id: "yes",
                defaultMessage: "Ya",
            }),
            cancelButtonText: intl.formatMessage({
                id: "no",
                defaultMessage: "Tidak",
            }),
            preConfirm: () => {
                const instance = getApiInstance();
                return instance.post(`/me/workshops/${item.id}/register`);
            },
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
        })
            .then((res) => {
                if (res.isConfirmed) {
                    queryClient.invalidateQueries(
                        `${queryCacheKey}-${churchId}`
                    );
                    if (res.value?.status === 202 && res.value.data.error) {
                        Swal.fire({
                            title: res.value.data.error,
                            icon: "warning",
                        });
                    } else {
                        setSelectedWorkshop(item.id);
                        navigate(0);
                        Swal.showLoading();
                    }
                }
            })
            .catch((exc) => {
                const res = exc.response;
                const errMessage = res.data.error ?? "Something went wrong!";
                Swal.fire("Error", errMessage, "error");
            });
    };

    if (query.isFetching || query.data?.length === 0) {
        return <></>;
    } else {
        return (
            <div className="mt-4">
                <h3 className="text-3xl text-black font-bold text-center">
                    <span className="text-3xl text-black font-bold text-center">
                        WORKSHOP
                    </span>
                </h3>
                <div className="card">
                    <div className="card-body">
                        {query.isFetched &&
                            query.data &&
                            renderWorkshop(query.data)}
                    </div>
                </div>
            </div>
        );
    }
}

export default WorkshopsCard;
