import React, { useRef, useState } from "react";
import PickupLocationCard from "./PickupLocation";
import { getApiInstance } from "../../utils/helper";
import Swal from "sweetalert2";

type Props = {
    dialogRef?: (dialogRef: HTMLDialogElement) => void;
    churchId: string;
    locationId?: string;
    callback?: (
        locationId: string,
        locationName: string,
        locationAddress: string
    ) => void;
};

function DialogPickupLocation(props: Props) {
    const dialogRef = useRef<HTMLDialogElement | null>();
    const [pickupLocation, setPickupLocation] = useState(
        props.locationId ?? ""
    );

    const handleSubmit = async () => {
        try {
            const instance = getApiInstance();
            dialogRef.current?.close();
            Swal.showLoading();
            const res = await instance.post(
                `/me/rereg-location/${pickupLocation}`
            );
            const data = res.data.result;
            if (props.callback) {
                props.callback(
                    pickupLocation,
                    data.new_location,
                    data.new_location_address
                );
            }
            Swal.close();
        } catch (exc) {
            Swal.fire({
                title: "Error",
                text: "Something went wrong! Please try again later.",
            }).then((val) => {
                if (val.isConfirmed) {
                    // munculkan lagi dialognya
                    dialogRef.current?.showModal();
                }
            });
        }
    };

    return (
        <>
            <dialog
                ref={(ref) => {
                    if (ref != null) {
                        dialogRef.current = ref;
                        if (props.dialogRef) {
                            props.dialogRef(ref);
                        }
                    }
                }}
                className="modal"
            >
                <div className="modal-box ">
                    <div>
                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                                ✕
                            </button>
                        </form>
                        <PickupLocationCard
                            churchId={props.churchId}
                            stateValue={pickupLocation}
                            setStateValue={setPickupLocation}
                        />
                        <div className="mt-4 text-right">
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary mr-4"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => dialogRef.current?.close()}
                                className="btn btn-outline btn-error"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </dialog>
        </>
    );
}

export default DialogPickupLocation;
