import "./register-page.scss";
import React, { useEffect, useMemo, useState } from "react";
import {
    getApiInstance,
    getFormattedPrice,
    hasOnlineSession,
    isUserAuthenticated,
} from "../utils/helper";
import moment from "moment";
import Swal from "sweetalert2";
import useEventItems from "../utils/useEventItems";
import { EventItem, UserRegistrant } from "../interfaces/interfaces";
import * as Sentry from "@sentry/react";
import RegistrationDetail from "./partials/RegistrationDetail";
import PickupLocationCard from "./partials/PickupLocation";
import { BaseFormGroup } from "./widgets/Form";
import RegistrantAgreement from "./partials/RegistrantAgreement";
import { FormattedMessage, useIntl } from "react-intl";
import WorkshopsCard from "./partials/WorkshopsCard";
import LoadingScreenWidget from "./widgets/LoadingScreenWidget";
import FeedbackWidget from "./widgets/FeedbackWidget";
import useFeedback from "../utils/useFeedback";

type Props = {
    registrant: UserRegistrant;
    setRegistrant: React.Dispatch<React.SetStateAction<UserRegistrant>>;
};

function RegisterForm({ registrant, setRegistrant }: Props) {
    const intl = useIntl();
    const [formRequest, setFormRequest] = useState({
        loading: false,
        canSubmit: true,
    });
    const serviceType: string = "on-site";
    const [currOfflineItem, setCurrOfflineItem] = useState<EventItem>();
    const { items, isLoading } = useEventItems({
        show_all: false,
        item_discounts: registrant.item_discounts,
    });
    const [pickupLocation, setPickupLocation] = useState("");
    const [showWorkshop, setShowWorkshop] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const { query: queryFeedback } = useFeedback(showFeedback);
    const offlineItems = useMemo(() => {
        if (!isLoading && items !== undefined) {
            return items?.filter((o) => !o.is_online);
        }
    }, [items, isLoading]);
    const onlineItem = hasOnlineSession()
        ? !isLoading && items !== undefined
            ? items?.find((o) => o.is_online)
            : undefined
        : undefined;

    useEffect(() => {
        if (registrant && items && items.length > 0) {
            // pilihkan default item
            const item = items.find(
                (o) => o.church_id === registrant.church_id
            );
            setCurrOfflineItem(item);
        }
        if (
            isRegistered(registrant) &&
            registrant.registration_status?.transaction.status === "PAID"
        ) {
            // setShowWorkshop(!registrant.workshop_name);
            const openDate = new Date("2300-05-20 23:59:00");
            // console.log(openDate);
            const now = new Date();
            now.getTime();
            if (now.getTime() > openDate.getTime()) {
                const isWorkshopSelected = !!registrant.workshop_id;
                setShowWorkshop(!isWorkshopSelected);
                setShowFeedback(isWorkshopSelected);
            } else {
                setShowWorkshop(true);
            }
        } else {
            setShowWorkshop(false);
            setShowFeedback(false);
        }
    }, [items, registrant]);

    const handleRegister = async () => {
        if (formRequest.loading) return;
        if (currOfflineItem === undefined && !onlineItem) {
            return;
        }
        if (currOfflineItem && currOfflineItem.left_qty <= 0) {
            Swal.fire({
                title: intl.formatMessage({
                    id: "registration.noQuota",
                    defaultMessage: "Quota tidak mencukupi",
                }),
                icon: "info",
            });
            return;
        }
        const ticketId =
            serviceType === "online" ? onlineItem!.id : currOfflineItem!.id;
        const formPayload = {
            ticket_id: ticketId,
            interpreter_service: null,
            pickup_location_id: pickupLocation,
        };
        setFormRequest({ loading: true, canSubmit: false });
        const instance = getApiInstance();
        try {
            const rawResponse = await instance.post(
                "/me/register",
                formPayload
            );
            // redirect ke halaman yang dituju
            window.location = rawResponse.data.result.redirect_url;
        } catch (error: any) {
            Sentry.captureException(error);
            if (error.response) {
                Swal.fire("Error", error.response.data.error, "error");
            } else {
                Swal.fire("Error", "Terjadi kesalahan!", "error");
            }
            setFormRequest({ loading: false, canSubmit: true });
        }
    };

    // handler upload bukti pembayaran
    const handleImageUpload = async (e: any) => {};

    const renderServiceForm = (serviceType: string) => {
        if (serviceType === "on-site") {
            return (
                <div>
                    <BaseFormGroup
                        label={intl.formatMessage({
                            id: "location.header",
                            defaultMessage: "Lokasi Event",
                        })}
                    >
                        <>
                            <select
                                id="selectEventLocation"
                                className="form-control"
                                value={currOfflineItem?.church_id}
                                onChange={(e) => {
                                    setCurrOfflineItem(
                                        offlineItems?.find(
                                            (o) =>
                                                o.church_id === e.target.value
                                        )
                                    );
                                }}
                            >
                                <option value={""}>
                                    --{" "}
                                    <FormattedMessage
                                        id="location.select"
                                        defaultMessage="Pilih Lokasi"
                                    />{" "}
                                    --
                                </option>
                                {offlineItems?.map((o, i) => {
                                    return (
                                        <option
                                            key={`ticket-${o.id}`}
                                            value={o.church_id}
                                            disabled={o.left_qty <= 0}
                                        >
                                            {o.church_name}
                                            {o.left_qty <= 0
                                                ? `" (${intl.formatMessage({
                                                      id: "event.noQuotaLabel",
                                                      defaultMessage:
                                                          "Quota Habis",
                                                  })})`
                                                : ""}
                                        </option>
                                    );
                                })}
                            </select>
                        </>
                    </BaseFormGroup>
                    <div className="mt-3">
                        <BaseFormGroup
                            label={intl.formatMessage({
                                id: "event.ticketPrice",
                                defaultMessage: "Harga Tiket",
                            })}
                        >
                            <div>{`Rp ${getFormattedPrice(
                                currOfflineItem?.price
                            )},-`}</div>
                        </BaseFormGroup>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="form-group">
                    <label htmlFor="">
                        <FormattedMessage
                            id="event.ticketPrice"
                            defaultMessage="Harga Tiket"
                        />
                    </label>
                    <div>{`Rp ${getFormattedPrice(onlineItem?.price)},-`}</div>
                </div>
            );
        }
    };

    const isRegistered = (registrant: UserRegistrant) => {
        return (
            registrant.registration_status?.registered &&
            registrant.registration_status?.transaction.status !== "EXPIRED" &&
            registrant.registration_status?.transaction.status !== "CANCELED"
        );
    };

    return (
        <>
            {/* page registrasi */}
            {/* <div className="mb-4 pl-4 pr-4">
                    <img
                        src="https://cf-assets.gms.church/08cf6b4d-82a0-4068-5448-dd764ed37300/512"
                        className="w-100"
                        alt="logo"
                    />
                </div> */}

            <h3 className="font-extrabold text-white text-center text-4xl header-description">
                REGISTRATION
            </h3>
            {/* Detail Registrasi */}
            <RegistrationDetail
                registrant={registrant}
                showFullDetail={!showFeedback}
                setRegistrant={setRegistrant}
                handleImageUpload={handleImageUpload}
            />
            {showWorkshop && <WorkshopsCard registrant={registrant} />}
            {/* Profile */}
            <div className="card">
                <div className="card-body">
                    <BaseFormGroup
                        label={intl.formatMessage({
                            id: "name",
                            defaultMessage: "Nama",
                        })}
                    >
                        {registrant.name}
                    </BaseFormGroup>
                    <BaseFormGroup
                        label={intl.formatMessage({
                            id: "phoneNumber",
                            defaultMessage: "No. HP",
                        })}
                    >
                        {registrant.cell_phone}
                    </BaseFormGroup>
                    <BaseFormGroup label="E-Mail">
                        {registrant.email}
                    </BaseFormGroup>
                    <BaseFormGroup hidden={isRegistered(registrant)}>
                        {renderServiceForm(serviceType)}
                    </BaseFormGroup>
                </div>
            </div>
            {!isRegistered(registrant) &&
                serviceType === "on-site" &&
                currOfflineItem &&
                currOfflineItem.church_id && (
                    <>
                        <h3 className="mt-4  text-xl font-extrabold text-center">
                            <FormattedMessage
                                id="toolkitPickupTitle"
                                defaultMessage={"PENGAMBILAN TOOLKIT"}
                            />
                        </h3>
                        <div className="card mt-2">
                            <div className="card-body">
                                <PickupLocationCard
                                    churchId={currOfflineItem.church_id}
                                    stateValue={pickupLocation}
                                    setStateValue={setPickupLocation}
                                />
                            </div>
                        </div>
                    </>
                )}
            {!isRegistered(registrant) && (
                <>
                    <div className="form-group">
                        <button
                            onClick={handleRegister}
                            disabled={
                                (serviceType === "on-site" &&
                                    !pickupLocation) ||
                                !formRequest.canSubmit
                            }
                            className="btn w-100 btn-submit"
                        >
                            Submit
                        </button>
                    </div>
                </>
            )}
            {showFeedback && queryFeedback.isFetched && (
                <>
                    <h3 className="mt-4 text-3xl font-bold text-center">
                        <span className="text-3xl font-bold text-center between-arrow">
                            FEEDBACK
                        </span>
                    </h3>
                    <FeedbackWidget
                        registrant={registrant}
                        feedback={queryFeedback.data}
                    />
                </>
            )}
            {/* <div className="mt-12 card">
                <div className="card-body">
                    <div className="text-center">
                        <FormattedMessage
                            id="contactUs"
                            defaultMessage="Butuh bantuan? Hubungi kami!"
                        />
                        <br />
                        <a
                            href={`https://wa.me/${getContactUsNumber()}?target=_blank`}
                        >
                            {getFormattedContactUsNumber()}
                        </a>
                    </div>
                </div>
            </div> */}
        </>
    );
}

function RegisterPage() {
    const intl = useIntl();
    const [registrant, setRegistrant] = useState({} as UserRegistrant);
    const [isLoading, setIsLoading] = useState(true);
    const doRelogin = () => {
        window.location.href = `https://accounts.gms.church/oauth/authorize?scope=profile&state=state&client_id=4751a125e78848887d937781b3f62d44&redirect_uri=${process.env.REACT_APP_BASE_URL}`;
    };

    useEffect(() => {
        if (isUserAuthenticated()) {
            const getData = async () => {
                const instance = getApiInstance();
                let rawResponse;
                try {
                    rawResponse = await instance.get("/me/profile");
                } catch (exc: any) {
                    const error = exc.toJSON();
                    if (error.status === 403 || error.status === 401) {
                        doRelogin();
                    } else {
                        Swal.fire(
                            "Eror",
                            intl.formatMessage({
                                id: "fatalError",
                                defaultMessage:
                                    "Terjadi kesalahan! Silahkan coba lagi nanti atau hubungi kami.",
                            }),
                            "error"
                        );
                    }
                    return;
                } finally {
                    setIsLoading(false);
                }

                const data: UserRegistrant = rawResponse.data.result;
                setRegistrant({
                    item_discounts: data.item_discounts,
                    mygms_id: data.mygms_id,
                    name: data.name,
                    birth_date: moment(data.birth_date).format("D MMM Y"),
                    cell_phone: data.cell_phone,
                    church_id: data.church_id,
                    email: data.email,
                    rereg_location_id: data.rereg_location_id,
                    rereg_location: data.rereg_location,
                    rereg_location_address: data.rereg_location_address,
                    registration_status: data.registration_status,
                    service_type: data.service_type,
                    workshop_id: data.workshop_id,
                    workshop_name: data.workshop_name,
                });
            };
            getData();
        } else {
            doRelogin();
        }
    }, []);

    const [currentFormStep, setCurrentFormStep] = useState(0);

    useEffect(() => {
        if (registrant.registration_status?.registered) {
            setCurrentFormStep(1);
        }
    }, [registrant]);

    const handleAgree = (isAgree: boolean) => {
        if (isAgree) {
            setCurrentFormStep(1);
        }
    };

    return (
        <>
            <div
                id="mainRegister"
                className="mx-auto max-w-9xl px-4 sm:px-6 lg:px-8"
            >
                <div className="app-content content">
                    <div className="content-wrapper">
                        {isLoading && (
                            <>
                                <LoadingScreenWidget />
                            </>
                        )}
                        {currentFormStep === 0 && !isLoading && (
                            <>
                                <RegistrantAgreement
                                    agreeCallback={handleAgree}
                                />
                            </>
                        )}
                        {currentFormStep === 1 && !isLoading && (
                            <RegisterForm
                                registrant={registrant}
                                setRegistrant={setRegistrant}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default RegisterPage;
