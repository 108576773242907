import React, { useId } from "react";
import { InputChangeEvent } from "../../interfaces/interfaces";
import { UseFormRegisterReturn } from "react-hook-form";

export type RadioOption = {
    value: string | number;
    label: JSX.Element | string;
    name?: string;
    custom_value_1?: any;
    isChecked?: boolean;
    disabled?: boolean;
    description?: JSX.Element | string;
    onChange?: InputChangeEvent;
    options?: UseFormRegisterReturn<any>;
};

type Props = {
    options: RadioOption[];
};

export default function RadioGroup({ options }: Props) {
    const id = useId();
    return (
        <fieldset className="mt-2">
            <div className="divide-y divide-gray-200">
                {options.map((option, i) => (
                    <div
                        key={`radio-${id}-${i}`}
                        className="relative flex items-start pb-4 pt-3.5"
                    >
                        <div className="min-w-0 flex-1 text-sm leading-6">
                            <label
                                htmlFor={`input-${id}-${i}`}
                                className="font-bold  block"
                            >
                                {option.label}
                                {option.description && (
                                    <p
                                        id={`account-${id}-${i}-description`}
                                        className="font-normal"
                                    >
                                        {option.description}
                                    </p>
                                )}
                            </label>
                        </div>
                        <div className="ml-3 flex h-6 items-center">
                            <input
                                id={`input-${id}-${i}`}
                                aria-describedby={`account-${id}-${i}-description`}
                                name={option.name ?? `input-${id}`}
                                type="radio"
                                disabled={!!option.disabled}
                                value={option.value}
                                checked={option.isChecked}
                                onChange={(e) =>
                                    option.onChange
                                        ? option.onChange(e)
                                        : undefined
                                }
                                {...(option.options ? option.options : "")}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </fieldset>
    );
}
