import React, { useRef } from "react";
import Swal from "sweetalert2";
import { AlertInfo } from "../widgets/Alert";
import { FormattedMessage, useIntl } from "react-intl";
import {
    getContactUsNumber,
    getFormattedContactUsNumber,
} from "../../utils/helper";

type Props = {
    agreeCallback?: Function;
};

function RegistrantAgreement(props: Props) {
    const intl = useIntl();
    const agreeRef = useRef<HTMLInputElement>(null);
    const handleAgree = () => {
        if (!agreeRef.current?.checked) {
            Swal.fire(
                "",
                intl.formatMessage({
                    id: "agreementWarning",
                    defaultMessage:
                        "Silahkan membaca dan menyetujui syarat dan ketentuan yang berlaku.",
                }),
                "warning"
            );
        } else {
            if (props.agreeCallback) props.agreeCallback(true);
        }
    };
    return (
        <>
            <h3 className="font-bold text-center mb-4 italic">
                <img src="/images/title.webp" alt="title" className="w-full" />
            </h3>
            <h3 className="font-extrabold text-white text-center text-2xl header-description">
                {
                    <FormattedMessage
                        id="tnc"
                        defaultMessage="Syarat dan Ketentuan"
                    />
                }
            </h3>
            <div className="card -mx-4 sm:mx-0">
                <div className="card-body pb-0">
                    <div className="mt-0 mb-0 text-black p-3 px-0 text-sm">
                        <div>
                            <ol className="text-left pl-4 list-decimal">
                                <li>
                                    Pendaftaran dan pembayaran hanya dilakukan
                                    melalui aplikasi GMS Church.
                                </li>
                                <li>
                                    Batas waktu maksimal pembayaran adalah 1x24
                                    jam dari waktu pendaftaran. Peserta yang
                                    melebihi batas waktu pendaftaran akan
                                    terhapus secara otomatis dan wajib mendaftar
                                    ulang (selama kuota masih tersedia)
                                </li>
                                <li>
                                    Peserta yang telah berhasil melakukan
                                    pembayaran akan memperoleh QR Code sebagai
                                    bukti pendaftaran.
                                </li>
                                <li>
                                    Peserta wajib menukarkan QR Code bukti
                                    pendaftaran dengan gelang untuk dapat
                                    mengakses ruangan sesi dan workshop.
                                </li>
                                <li>
                                    Gelang hanya bisa digunakan sesuai dengan
                                    lokasi yang telah didaftarkan.
                                </li>
                                <li>
                                    Peserta wajib menjaga agar gelang tidak
                                    rusak atau hilang.
                                </li>
                                <li>
                                    Tersedia bonus merchandise dengan kuota
                                    terbatas bagi peserta yang mendaftar dan
                                    membayar lebih awal.
                                </li>
                                <li>
                                    Peserta yang tidak dapat hadir dan atau
                                    mengundurkan diri tidak dapat mengajukan
                                    pengembalian dana (refund).
                                </li>
                                <li>
                                    Apabila terdapat pertanyaan, silakan
                                    mengirimkan DM (Direct Message) ke instagram{" "}
                                    <a href="https://instagram.com/gmsbizcon?target=_blank">
                                        @gmsbizcon
                                    </a>
                                    .
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <p className="text-left mt-2 mb-2">
                    <label htmlFor="checkAgree">
                        <input
                            ref={agreeRef}
                            id="checkAgree"
                            className="check"
                            type="checkbox"
                        />
                        <FormattedMessage
                            id="agreementMessage"
                            defaultMessage="
                        Saya telah membaca dan menyetujui setiap ketentuan yang
                        berlaku."
                        />
                    </label>
                </p>
                <button
                    type="button"
                    onClick={handleAgree}
                    className="w-full btn btn-primary"
                >
                    <FormattedMessage
                        id="btnNext"
                        defaultMessage="Selanjutnya"
                    />
                </button>
            </div>
        </>
    );
}

export default RegistrantAgreement;
